import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@CommonLib/components/material/material.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavListComponent } from './layout/sidenav-list/sidenav-list.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AppSettings } from './shared/appsettings';
import { map } from 'rxjs';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { GlobalErrorHandlerService } from './shared/global-error-handler.service';
import { AlertModule } from './_alert';
import { AuthQueueComponent } from './shared/components/queue/auth-queue.component';
import { TaskQueueComponent } from './shared/components/queue/queue-task.component';
import { TeamQueueAuthsComponent } from './home/team-queue-auths/team-queue-auths.component';
import { MyQueueAuthsComponent } from './home/my-queue-auths/my-queue-auths.component';
import { TeamQueueTasksComponent } from './home/team-queue-tasks/team-queue-tasks.component';
import { MyQueueTasksComponent } from './home/my-queue-tasks/my-queue-tasks.component';
import { DatePipe } from '@angular/common';
import { SnapshotComponent } from './home/snapshot/snapshot.component';
import { TeamQueueFaxesComponent } from './home/team-queue-faxes/team-queue-faxes.component';
import { TableModule } from './shared/components/table/table.module';
import { MemberSearchComponent } from './home/member-search/member-search.component';
import { DialogModule } from '@CommonLib/components/dialog/dialog.module';
import { DialogTaskModule } from './shared/components/dialog/dialog-task/dialog-task.module';
import { DialogFaxModule } from './shared/components/dialog/dialog-fax/dialog-fax.module';
import { DialogAuthModule } from './shared/components/dialog/dialog-auth/dialog-auth.module';
import { AuthSearchModule } from './shared/components/auth-search/auth-search.module';
import { RefreshModule } from './shared/components/refresh/refresh.module';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ConfirmDialogComponent } from './shared/components/dialog/dialog-confirm/dialog-confirm.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { TaskFilterComponent } from './shared/components/filter/task-filter/task-filter.component';
import { DirectivesModule } from './shared/directive/directives.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMaskDirective, IConfig } from 'ngx-mask';
import { AuthFilterComponent } from './shared/components/filter/auth-filter/auth-filter.component';
import { PipesModule } from '@CommonLib/pipes/pipes.module';
import { PhoneFormatPipe } from '@CommonLib/pipes/phone-format.pipe';
import { CalendarModule } from 'primeng/calendar'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FaxQueueComponent } from './shared/components/queue/fax-queue/fax-queue.component';
import { MyQueueFaxesComponent } from './home/my-queue-faxes/my-queue-faxes.component';

export function initialize(http: HttpClient, config: AppSettings) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      http.get<AppSettings>('./assets/appsettings.json')
        .pipe(
          map((x: AppSettings) => {
            config.formServiceBaseUrl = x.formServiceBaseUrl;
            config.autoSaveIntervalSeconds = x.autoSaveIntervalSeconds;
            config.lookupServiceBaseUrl = x.lookupServiceBaseUrl;
            config.memberServiceBaseUrl = x.memberServiceBaseUrl;
            config.apiRoot = x.apiRoot;
            config.clientId = x.clientId;
            config.clientRoot = x.clientRoot;
            config.idpAuthority = x.idpAuthority;
            config.postLogoutRedirectUri = x.postLogoutRedirectUri;
            config.redirectUri = x.redirectUri;
            config.customerId = + x.customerId;
            config.productId = x.productId;
            config.silentRedirectUri = x.silentRedirectUri;
            config.taskServiceBaseUrl = x.taskServiceBaseUrl;
            config.configServiceBaseUrl = x.configServiceBaseUrl;
            config.reportServiceBaseUrl = x.reportServiceBaseUrl;
            config.ruleEngineSvcBaseUrl = x.ruleEngineSvcBaseUrl;
            config.hiddenValues = x.hiddenValues;
            config.mcgInformedByBaseUrl = x.mcgInformedByBaseUrl;
            config.storageServiceBaseUrl = x.storageServiceBaseUrl;
            config.isUAT = x.isUAT;
            config.idleTimeoutSeconds = x.idleTimeoutSeconds;
            config.maxAvatarFileSize = x.maxAvatarFileSize;
            config.features = x.features;
            config.submitStatuses = x.submitStatuses;
            config.samlEndpoint = x.samlEndpoint;
            config.environment = x.environment;
            config.signalRSvcBaseUrl = x.signalRSvcBaseUrl;
            config.alertSvcBaseUrl = x.alertSvcBaseUrl;
            config.powerBiSvcBaseUrl = x.powerBiSvcBaseUrl;
            config.ediClientURL278 = x.ediClientURL278;
            config.maxQueueCount = x.maxQueueCount;
            config.refreshInterval = x.refreshInterval;
            resolve(true);
          })
        ).subscribe();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavListComponent,
    AuthQueueComponent,
    TaskQueueComponent,
    TeamQueueAuthsComponent,
    MyQueueAuthsComponent,
    TeamQueueTasksComponent,
    MyQueueTasksComponent,
    SnapshotComponent,
    TeamQueueFaxesComponent,
    MemberSearchComponent,
    SpinnerComponent,
    ConfirmDialogComponent,
    TaskFilterComponent,
    AuthFilterComponent,
    FaxQueueComponent,
    MyQueueFaxesComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    AlertModule,
    TableModule,
    DialogModule,
    DialogTaskModule,
    DialogFaxModule,
    DialogAuthModule,
    AuthSearchModule,
    RefreshModule,
    DirectivesModule,
    PipesModule,
    ScrollingModule,
    MatTabsModule,
    NgxMaskDirective,
    CalendarModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  exports: [
  ],
  providers:
    [AppSettings, {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [
        HttpClient,
        AppSettings
      ],
      multi: true
    },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
      { provide: DatePipe },
      { provide: PhoneFormatPipe},
      { provide: ConfirmDialogComponent },
      { provide: Title },
      { provide: BnNgIdleService }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }