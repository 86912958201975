import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { Subject, Subscription, of, delay, BehaviorSubject } from 'rxjs';
import { AppSettings } from '../shared/appsettings';
import { ConfigService } from './config-service.service';
import { SignalrService } from './signalr.service';
import { FormService } from './form-service.service';
import { LookupService } from './lookup.service';
import { DocumentService } from '@CommonLib/services/docStorage.service';
import * as moment from 'moment-timezone';
import { TOKEN_KEY, USER_KEY, FILTERS_KEY, MANAGERLIST_KEY } from '../../assets/constants';
import { EventService } from '@CommonLib/services/events.service';

@Injectable({
  providedIn: 'root'
})
/**
 * @brief 1/25/2024 Changed DocumentService reference to Common project
 */
export class AuthService {
  private _userManager!: UserManager;
  private _user!: User | null;
  private _loginChangedSubject = new Subject<boolean>();
  public loginChanged = this._loginChangedSubject.asObservable();
  private _userName = new Subject<string | undefined>();
  public userName = this._userName.asObservable();
  public loginComplete = new BehaviorSubject(false);

  private get idpSettings(): UserManagerSettings {
    return {
      authority: this.config.idpAuthority,
      client_id: this.config.clientId,
      redirect_uri: this.config.redirectUri,
      scope: "openid profile",
      response_type: "code",
      post_logout_redirect_uri: this.config.postLogoutRedirectUri,
      automaticSilentRenew: true,
      silent_redirect_uri: this.config.silentRedirectUri,
      revokeAccessTokenOnSignout: true,
      includeIdTokenInSilentRenew: true
    }
  }

  tokenSubscription = new Subscription()
  timeout:any;
  constructor(private config: AppSettings, private signalr: SignalrService, private configService: ConfigService,
    private formService: FormService, private lookupService: LookupService, private documentService: DocumentService,
    private eventService: EventService) {
    this._userManager = new UserManager(this.idpSettings);

    this._userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
      this.logout();
    });
    this._userManager.events.addAccessTokenExpiring(() => {
      this._userManager.signinSilent({ scope: this.idpSettings.scope, response_type: this.idpSettings.response_type })
        .then((user: User) => {
          //this.saveToken(user.access_token);
          this.signalr.refreshConnection();
        })
        .catch((error: Error) => {
          console.log('Silent Refresh Error: ' + error.message);
          this._userManager.getUser()
            .then((user) => {
              if (user !== null) {
                this.handleUser(user);
                this.signalr.refreshConnection();
              }
            });
        });
    });
    this._userManager.events.addSilentRenewError(e => {
      console.log(e.message);
    })
    this._userManager.getUser().then(user => {
      this._user = user;
    });
  }

  public login = () => {
    return this._userManager.signinRedirect();
  }

  public finishLogin = (): Promise<User> => {
    return this._userManager.signinRedirectCallback()
      .then(user => {
          this.handleUser(user);
          return user;
      })
  }

  private handleUser(user: User) {
    this._user = user;
    this._loginChangedSubject.next(this.checkUser(user));
    this._userName.next(user.profile.name);
    this.saveUser(user);
    this.saveToken(user.access_token);

    this.loadData(user);
    this.loginComplete.next(true);
  }

  private loadData(user: User){
    if (this.isLoggedIn()){
      this.configService.getActiveUsersByUserName([user.profile.preferred_username!]);
      this.configService.userListById.subscribe(data => {
        if (data.length > 0) {
          window.sessionStorage.removeItem('user_config');
          window.sessionStorage.setItem('user_config', JSON.stringify(data));
          this.formService.getAuthIntakeWorkQueueByOwner(user.profile.preferred_username!).subscribe();
        }
      });
      this.configService.getUserGroups(user.profile.preferred_username)?.subscribe(data => {
        if (data.succeeded && data.data?.length && data.data?.length > 0) {
          const queueIds = data.data.map(q => q.queueId);
          this.formService.getAuthIntakeWorkQueueByGroup(queueIds).subscribe();
        }
      })

      this.lookupService.getAllLookupValues();
      this.configService.getAllQueues();
      this.configService.getUserFaxGroups(user.profile.preferred_username);
      this.documentService.getFaxList(this.config.storageServiceBaseUrl);
      this.formService.getReviewOutcomeReasonMap();
      this.configService.getActiveUsers();
      this.configService.getManagers();
      this.configService.getCustomLinks().subscribe();
      this.configService.getAppSettingConfig().subscribe(settings => {
        if (settings && settings.length > 0){
          const tz = settings.find(s => s.appSettingKey.toLowerCase() == 'producttimezone' && s.isActive);
          if (tz && tz.appSettingValue) {
            this.config.timeZone = moment.tz(tz?.appSettingValue).format('z');
          } else {
            const mtz = moment.tz.guess();
            this.config.timeZone = moment.tz(mtz).format('z');
          }
          this.eventService.TimeZone();
        }
      });
    }
  }

  public BrowserRefresh(): void {
    let loggingOut = false;
    this._loginChangedSubject.subscribe(l => {
      if (l && l == true){
        loggingOut = false;
      } else {
        loggingOut = true;
      }
    })
    this.isLoggedIn();
    if (!loggingOut && this._user){
      this.loadData(this._user);
    }
  }

  private saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  private saveUser(user: any): void {
    const jwtHelper = new JwtHelperService();
    let t = jwtHelper.getTokenExpirationDate(user.access_token)?.valueOf();
    if (t !== undefined && t !== null) {
      this.timeout = t - new Date().valueOf();
    }
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public isAuthenticated = (): Promise<boolean> => {
    return this._userManager.getUser()
      .then(user => {
        if (this._user !== user) {
          this._loginChangedSubject.next(this.checkUser(user));
        }
        this._user = user;
        return this.checkUser(user);
      })
  }

  private checkUser = (user: User|null): boolean => {
    return !!user && !user.expired;
  }

  public logout = () => {
    window.sessionStorage.removeItem(FILTERS_KEY);
    this._userManager.signoutRedirect();
  }

  expirationCounter(timeout:any) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      console.log('EXPIRED!!');

      this.logout();
    });
  }

  public finishLogout = () => {
    this._user = null;
    this._loginChangedSubject.next(false);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(MANAGERLIST_KEY);
    return this._userManager.signoutRedirectCallback();
  }

  public getAccessToken = (): Promise<string | null> => {
    return this._userManager.getUser()
      .then(user => {
        if (user != null) {
          this.saveToken(user.access_token);
        }
        return !!user && !user.expired ? user.access_token : null;
      })
  }

  isLoggedIn(): boolean {
    const userString = window.sessionStorage.getItem(USER_KEY);
    if (userString == null) {
      return false;
    }
    this._user = JSON.parse(userString);
    return this._user != null && !this._user.expired;
  }

  public refreshToken(): string|null{
    this._userManager.signinSilent({ scope: this.idpSettings.scope, response_type: this.idpSettings.response_type })
        .then((user: User) => {
          this.saveToken(user.access_token);
          return user.access_token;
        })
        .catch((error: Error) => {
          console.log('Silent Refresh Error: ' + error.message);
          this._userManager.getUser()
            .then((user) => {
              if (user !== null) {
                this.saveToken(user.access_token);
                return user.access_token;
              }
              return undefined;
            });
        });

        return null;
  }
}
